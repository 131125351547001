.form {
  position: relative;
}

.border {
  display: flex;
  align-items: center;
  border: 2px solid var(--gray-9);
  border-radius: var(--br-base);
  max-width: 100%;
}

.border:focus-within {
  border-color: var(--fg);
}

.input {
  border: none;
  border-radius: 0;
  flex: 1;
  margin: 0;
  padding: var(--spacing-s) var(--spacing-xs);
  width: 100%; /* somehow fixes it being too wide! */
}

.label {
  cursor: pointer;
  display: inline;
  margin-top: 0;
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-xs);
}

.label svg {
  display: block;
}

.button {
  background-color: transparent;
  border-left: 2px solid var(--gray-9);
  border-radius: 0 var(--br-base) var(--br-base) 0;
  color: inherit;
  font-family: var(--monospace);
  margin: 0;
  padding: var(--spacing-s) var(--spacing-m);
}

.button[disabled] {
  background: transparent;
  color: var(--gray-5);
}

.button:hover,
.button:focus {
  top: 0;
}

.input:focus + .button {
  border-left-color: var(--fg);
}

.error {
  position: absolute;
  width: var(--max-width);
  max-width: calc(100% - 2 * var(--spacing-m));
  margin: 0 auto;
  top: 100%;
  z-index: 1;
}
