.forbidden,
.restrictedTo {
  display: flex;
  gap: var(--spacing-xs);
}

.crown,
.restrictedTo > *:last-child {
  flex: 0 0 var(--spacing-m);
}

.crown {
  margin: 0 var(--spacing-xs);
}

.restrictedTo,
.forbidden,
.forbidden:focus,
.forbidden:hover {
  color: var(--gray-6);
}
